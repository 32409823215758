/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FileType { 
    file_type_id?: number;
    file_type_name?: string;
    file_type_name_en?: string;
    file_type_alias?: string;
    file_type_hidden?: number;
    file_type_can_expire?: number;
    file_type_icon?: string;
    file_type_essential?: number;
    file_type_client?: number;
    cert_hash?: string;
    visible_for_client?: number;
    description?: string;
    description_en?: string;
    url?: string;
    ord?: number;
    is_required_document?: number;
    is_suggested_document?: number;
    example_file_id?: number;
}

