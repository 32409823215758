/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineResponse2004Jobs { 
    hash?: string;
    title?: string;
    image_id?: number;
    area?: string;
    employment_type?: string;
    posted_at?: string;
    business_sector?: Array<string>;
}

