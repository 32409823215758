/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A shift user object
 */
export interface ShiftUser { 
    id?: number;
    shift_id?: number;
    user_id?: number;
    datetime_apply?: string;
    assigned?: number;
    assigned_by?: number;
    datetime_assigned?: string;
    withdrawn?: string;
    withdrawn_by?: number;
    trading?: number;
    datetime_trading?: string;
    unassigned_by?: number;
    datetime_unassigned?: string;
    traded_with?: number;
    begin_override?: string;
    end_override?: string;
    break_time_override?: number;
    absent?: number;
    absent_unexcused?: number;
    review_comment?: string;
    reviewer_id?: number;
    reviewed_timestamp?: string;
    withdrawn_for?: number;
}

