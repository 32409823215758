/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceOperationClient } from './serviceOperationClient';


/**
 * A service operation
 */
export interface ShiftDetails { 
    service_operation_id?: number;
    service_operation_description?: string;
    service_operation_client?: ServiceOperationClient;
    service_operation_location?: number;
    service_operation_begin?: string;
    service_operation_end?: string;
    service_operation_creator?: number;
    service_operation_booked?: string;
    service_operation_client_person_in_charge?: number;
    service_operation_address_line_2?: string;
    service_operation_booking_id?: number;
    client_deduction_date?: string;
    client_deducted_by?: number;
    client_invoice_id?: number;
    employment_type?: number;
    service_operation_address_street?: string;
    service_operation_address_nr?: string;
    service_operation_address_zip?: number;
    service_operation_address_town?: string;
    lat?: number;
    lng?: number;
    add_radius?: number;
    operation_address_id?: number;
    applied_assigned?: number;
    assigned?: number;
    cant_apply?: boolean;
    friend_hidden?: ShiftDetails.FriendHiddenEnum;
    shift_allow_auto_assign?: boolean;
    shift_begin?: string;
    shift_break_time?: number;
    shift_cancelled?: boolean;
    shift_close_application_hours_before?: number;
    shift_created_timestamp?: string;
    shift_dashboard_highlight?: boolean;
    shift_demand?: number;
    shift_driver_shift?: boolean;
    shift_driveway?: string;
}
export namespace ShiftDetails {
    export type FriendHiddenEnum = 'hidden' | '';
    export const FriendHiddenEnum = {
        Hidden: 'hidden' as FriendHiddenEnum,
        Empty: '' as FriendHiddenEnum
    };
}


