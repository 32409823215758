import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class CachingService {
  private storageInstance: Storage | null = null;


  constructor(private storage: Storage) {
    this.init();
   }

  async init() {
    this.storageInstance = await this.storage.create();
  }

  withStorage<T>(call: Observable<T>,  invalidate = false): Observable<T> {
    return new Observable<T>((observer) => {
      //console.log(call.name + JSON.stringify(callArgs));
      this.storageInstance.get("a").then((datum) => {
        if (datum) {
          observer.next(JSON.parse(datum) as T);
          observer.complete();
          return;
        }
        call.subscribe((d) => {
          this.storageInstance.set("a", JSON.stringify(d));
          console.log("setting", d);
          observer.next(d);
          observer.complete();
        })
      });
    });
  }
}
