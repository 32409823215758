/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserCommunicationHistory { 
    title: string;
    datetime: string;
    type: UserCommunicationHistory.TypeEnum;
    details?: string;
}
export namespace UserCommunicationHistory {
    export type TypeEnum = 'message' | 'adminAction' | 'other';
    export const TypeEnum = {
        Message: 'message' as TypeEnum,
        AdminAction: 'adminAction' as TypeEnum,
        Other: 'other' as TypeEnum
    };
}


