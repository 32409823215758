import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthService } from 'ionic-appauth';
import { InternalUserService } from './data/services/internal-user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private auth: AuthService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.auth.init();
    (await this.auth.getValidToken()).isValid()
    
  }

}
