import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from 'ionic-appauth';
import { Observable } from 'rxjs';
import { setRedirect } from './data/redirect.util';
import { InternalUserService } from './data/services/internal-user.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AddHeaderInterceptor } from './core/http-interceptor';


@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private auth: AuthService,
    private readonly navCtrl: NavController,
    private readonly userService: InternalUserService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable((observer) => {
      this.auth.initComplete$.subscribe(async () => {
        //await this.auth.loadTokenFromStorage();
        this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
          if(!isAuthenticated) {
            this.navCtrl.navigateRoot('/login');
            observer.next(!!isAuthenticated);
          } else {
            this.auth.getValidToken().then((t) => {
              console.log('valid token');
              this.userService.setToken(t);
              observer.next(!!isAuthenticated);
            });
          }

        });
      });
    });
  }
}
