/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientLocation { 
    client_location_id?: number;
    client_id?: number;
    description?: string;
    nr?: string;
    street?: string;
    zip?: string;
    town?: string;
    text?: string;
    province?: string;
    country_code?: string;
}

