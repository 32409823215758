import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'ionic-appauth';
import { Observable } from 'rxjs';
import { InternalUserService } from '../data/services/internal-user.service';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(private readonly auth: AuthService, private readonly internalUserService: InternalUserService) {

   /* this.auth.getValidToken().then((t) => {
      this.accessToken = t.accessToken;
      console.log(t);
    });*/
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    console.log(this.internalUserService.accessToken && !req.url.includes('id.webeins.net'));
    if (this.internalUserService.accessToken && !req.url.includes('id.webeins.net')) {
      if (this.internalUserService.accessTokenExpiresAt - 120 < Date.now() / 1000) {
        // refresh token if we have only two minue left
        this.auth.refreshToken().then(() => {
          console.log('refreshed! access token');
          const clonedRefreshedRequest = req.clone({
            headers: req.headers.append('Authorization', 'Bearer ' + this.internalUserService.accessToken)
          });

          // Pass the cloned request instead of the original request to the next handle
          return next.handle(clonedRefreshedRequest);
        });
      } else {
        const clonedRequest = req.clone({ headers: req.headers.append('Authorization', 'Bearer ' + this.internalUserService.accessToken) });

        // Pass the cloned request instead of the original request to the next handle
        return next.handle(clonedRequest);
      }
    }
    return next.handle(req);

  }
}
