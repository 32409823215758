/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserMetaData { 
    onboarding_status?: string;
    self_onboarding_status?: string;
    user_meta_data_active_payroll_software?: string;
    user_meta_data_month_workload?: string;
    user_meta_data_next_season_active?: string;
    user_meta_data_operation_location?: string;
    user_meta_data_season_active?: string;
    user_meta_data_user_id?: string;
    user_meta_data_webcast?: string;
    user_meta_data_working_model?: string;
    origin?: string;
}

