/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FileMetaData { 
    file_meta_data_id?: number;
    file_id?: number;
    valid_from?: string;
    valid_to?: string;
    sent_expire_warning?: number;
    validator?: number;
}

