import { Injectable } from '@angular/core';
import { TokenResponse } from '@openid/appauth';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from 'ionic-appauth';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserData, WebeinsLegacyService } from '../webeins-legacy/generated';
import { CachingService } from './caching.service';

@Injectable({
  providedIn: 'root'
})
export class InternalUserService {
  userData: any;
  loginAvailable: boolean;
  accessToken: string;

  /** Unix TIMESTAMP */
  accessTokenExpiresAt: number;

  // holds a value anyways
  userData$ = new BehaviorSubject<any|null>(null);

  // only pushes a value if the login was performed
  userDataAfterLogin$ = new Subject<any|false>();

  constructor(
    private auth: AuthService,
    private legacyService: WebeinsLegacyService,
    private cachingService: CachingService
  ) {
    this.auth.token$.subscribe((t) => {
      this.setToken(t);
    });
  }

  setToken(t: TokenResponse) {
    console.log(t);
    this.accessTokenExpiresAt = Date.now() / 1000 + t.expiresIn;
    this.accessToken = t.accessToken;
  }

  loadUserData() {
    this.auth.user$.subscribe((u) => {
      this.loginSuccess(u);
    });
    void this.auth.loadUserInfo().then(p => {
    });
  }

  getUserData() {
    return this.userData;
  }

  getWebeinsUserData(): Observable<UserData> {
    return this.cachingService.withStorage<UserData>(this.legacyService.getOwnUserProfile());
  }

  /**
   * Forces User login
   */
 /* login(force = false) {
    if (force || !this.checkCachedLogin()) {
      this.oauthService.loadDiscoveryDocumentAndLogin().then((loginSuccess) => {
        if (loginSuccess) {
          if (this.oauthService.hasValidAccessToken()) {
            void this.oauthService.loadUserProfile().then((p: OauthResponse) => {
              this.cacheProfile(p);
              this.userData = p;
              this.loginSuccess(p);
            });
          }
        } else {
          this.loginError();
        }
      }).catch(() => {
        this.loginError();
      });
    }
  }

  checkLoginAvailability() {
    if (!this.checkCachedLogin()) {
      this.oauthService.loadDiscoveryDocumentAndTryLogin().then((loginSuccess) => {
        if (loginSuccess) {
          if (this.oauthService.hasValidAccessToken()) {
            void this.oauthService.loadUserProfile().then((p: OauthResponse) => {
              this.cacheProfile(p);
              this.userData = p;
              this.loginSuccess(p);
            });
          }
        } else {
          this.loginError();
        }
      }).catch(() => {
        this.loginError();
      });
    }
  }

  checkCachedLogin(): boolean {
    if (this.checkLoginCookie()) {
      console.error('Adjust Loginflow for LoginCookie');
    }
    if (this.oauthService.hasValidAccessToken() && this.loadCachedProfile()) {
      const p = this.loadCachedProfile();
      this.userData = p;
      this.loginSuccess(p);
      return true;
    }
    return false;
  }
*/
  private loadCachedProfile() {
    const str = sessionStorage.getItem('iam_profile');
    return str ? JSON.parse(str) : null;
  }

  private cacheProfile(profile: any): void {
    console.log('caching', profile);
    sessionStorage.setItem('iam_profile', JSON.stringify(profile));
  }

  private clearProfileCache(): void {
    sessionStorage.removeItem('iam_profile');
  }


  private loginSuccess(user: any) {
    this.setLoginCookie();
    this.userData = user;
    this.userDataAfterLogin$.next(user);
    this.userData$.next(user);
  }

  private loginError() {
    this.deleteLoginCookie();
    this.clearProfileCache();
    this.userData = undefined;
    this.userDataAfterLogin$.next(false);
  }

  private setLoginCookie(expireDays = 1): void {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `COOKIE_LOGIN=1; ${expires}`;
  }

  private checkLoginCookie(): boolean {
    const cookies = document.cookie;
    const cookieName = `COOKIE_LOGIN=`;
    return cookies.indexOf(cookieName) >= 0;
  }

  private deleteLoginCookie(): void {
    this.setLoginCookie(-1);
  }
}
