/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserCommunicationHistoryInsertObject { 
    via: UserCommunicationHistoryInsertObject.ViaEnum;
    comment: string;
}
export namespace UserCommunicationHistoryInsertObject {
    export type ViaEnum = 'email' | 'sms' | 'phone' | 'personal';
    export const ViaEnum = {
        Email: 'email' as ViaEnum,
        Sms: 'sms' as ViaEnum,
        Phone: 'phone' as ViaEnum,
        Personal: 'personal' as ViaEnum
    };
}


