/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FormShareBudget { 
    start_at?: string;
    end_at?: string;
    amount?: string;
    name?: string;
    type?: FormShareBudget.TypeEnum;
    additional?: string;
}
export namespace FormShareBudget {
    export type TypeEnum = 'per_click' | 'per_success' | 'per_impression' | 'timespan' | 'per_day';
    export const TypeEnum = {
        PerClick: 'per_click' as TypeEnum,
        PerSuccess: 'per_success' as TypeEnum,
        PerImpression: 'per_impression' as TypeEnum,
        Timespan: 'timespan' as TypeEnum,
        PerDay: 'per_day' as TypeEnum
    };
}


