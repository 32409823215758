import { HttpClientModule } from '@angular/common/http';
import { NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { AuthService, Browser } from 'ionic-appauth';
import { Requestor, StorageBackend } from '@openid/appauth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';
import { authFactory } from './core/factories/auth.factory';
import { NgHttpService } from './core/ng-http.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddHeaderInterceptor } from './core/http-interceptor';
import { Nl2brPipe } from './pipes/nl2br.pipe';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
        HttpClientModule, FontAwesomeModule,
        IonicStorageModule.forRoot({
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        })
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: StorageBackend,
            useClass: CapacitorSecureStorage
        },
        {
            provide: Requestor,
            useClass: NgHttpService
        },
        {
            provide: Browser,
            useClass: CapacitorBrowser
        },
        {
            provide: AuthService,
            useFactory: authFactory,
            deps: [Platform, NgZone, Requestor, Browser, StorageBackend]
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: AddHeaderInterceptor,
            multi: true,
        }, AddHeaderInterceptor],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, fab, far);
	}
}
