/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CalculatedUserdata { 
    calculated_timestamp?: string;
    earnings_expected_sum_month?: number;
    earnings_quotient_month?: number;
    earnings_sum_month?: number;
    hours_expected_sum_month?: number;
    hours_sum_month?: number;
    hours_sum_year?: number;
    karma?: number;
    shifts_absent_count?: number;
    shifts_absent_unexcused_count?: number;
    shifts_applied_sum_last30days?: number;
    shifts_appliedbegin_avg?: number;
    shifts_cancellation_time_avg?: number;
    shifts_count?: number;
    shifts_count_month?: number;
    shifts_count_year?: number;
    shifts_expected_count_month?: number;
    shifts_withdrawn_count?: number;
    user_id?: number;
}

