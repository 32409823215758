/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserAddress { 
    user_address_id?: number;
    street?: string;
    nr?: string;
    town?: string;
    zip?: string;
    lat?: number;
    lng?: number;
    province?: number;
    kreis?: string;
}

