/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientContact { 
    client_contact_id?: number;
    client_id?: number;
    image_id?: number;
    name?: string;
    phone?: string;
    mail?: string;
    position?: string;
    description?: string;
}

