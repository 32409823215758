/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A shift payment data object
 */
export interface ShiftPaymentData { 
    payoutAmount?: number;
    taxAmount?: number;
    total?: number;
    taxRelevant?: number;
}

