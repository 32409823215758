/**
 * WebEins Legacy Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserFilter { 
    name: string;
    alias: string;
    filter_type: UserFilter.FilterTypeEnum;
    filter_data?: { [key: string]: object; };
    filter_input?: UserFilter.FilterInputEnum;
}
export namespace UserFilter {
    export type FilterTypeEnum = 'select' | 'multiselect' | 'yn' | 'input' | 'input_range';
    export const FilterTypeEnum = {
        Select: 'select' as FilterTypeEnum,
        Multiselect: 'multiselect' as FilterTypeEnum,
        Yn: 'yn' as FilterTypeEnum,
        Input: 'input' as FilterTypeEnum,
        InputRange: 'input_range' as FilterTypeEnum
    };
    export type FilterInputEnum = 'datetime';
    export const FilterInputEnum = {
        Datetime: 'datetime' as FilterInputEnum
    };
}


